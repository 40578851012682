// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-event-event-tsx": () => import("./../../../src/components/templates/Event/Event.tsx" /* webpackChunkName: "component---src-components-templates-event-event-tsx" */),
  "component---src-components-templates-exhibition-exhibition-tsx": () => import("./../../../src/components/templates/Exhibition/Exhibition.tsx" /* webpackChunkName: "component---src-components-templates-exhibition-exhibition-tsx" */),
  "component---src-components-templates-news-news-tsx": () => import("./../../../src/components/templates/News/News.tsx" /* webpackChunkName: "component---src-components-templates-news-news-tsx" */),
  "component---src-components-templates-page-page-tsx": () => import("./../../../src/components/templates/Page/Page.tsx" /* webpackChunkName: "component---src-components-templates-page-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

